<script>
	export let showOnPx = 150;
	export let bottomOffset = undefined;
	let hidden = true;

	export function goTop() {
		document.body.scrollIntoView();
	}

	function scrollContainer() {
		return document.documentElement || document.body;
	}

	function handleOnScroll() {
		if (!scrollContainer()) {
			return;
		}

		if (scrollContainer().scrollTop > showOnPx) {
			hidden = false;
		} else {
			hidden = true;
		}
	}
</script>

<svelte:window on:scroll={handleOnScroll} />

<div
	class="
        back-to-top grid h-10 w-10 cursor-pointer content-center rounded-full bg-indigo-600 text-white
        hover:h-12 hover:w-12 hover:border-4 hover:border-indigo-600 {$$props.class}
    "
	style={bottomOffset ? `bottom: ${bottomOffset}px` : ''}
	on:click={goTop}
	class:hidden
>
	<svg class="mx-auto h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
			clip-rule="evenodd"
		/>
	</svg>
</div>

<style>
	.back-to-top {
		opacity: 1;
		transition: opacity 0.5s, visibility 0.5s;
		position: fixed;
		z-index: 99;
		right: 20px;
		user-select: none;
		bottom: 20px;
	}

	.back-to-top.hidden {
		opacity: 0;
		visibility: hidden;
	}
</style>
